@tailwind preflight;

@tailwind components;

body,
html,
#root {
  height: 100%;
  @apply font-sans;
  @apply bg-grey-025;
}

@tailwind utilities;
